import React, { Fragment } from "react"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import groupBy from "lodash.groupby"
import GroupByKind from "./components/GroupByKind"

const Table = ({ kinds, values, openServiceFormModal, openCloneModal, markServiceForDestroy, onCopyServicesAdded }) => {
  const services = values.services.filter((i) => !i._destroy)

  const innerCostSum = services.reduce((a, b) => a + parseMoney(b.innerCost), 0)
  const outerCostSum = services.reduce((a, b) => a + parseMoney(b.outerCost), 0)
  const groupByKind = groupBy(services, "kind.value")

  const acCost = values.isWithAc ? outerCostSum * parseInt(values.ac) * 0.01 : 0
  const ndsCost =
    values.calculationKind.value === "usn"
      ? 0
      : (outerCostSum + acCost) * (values.calculationKind.value === "nds" ? 0.2 : 0.05)

  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th></th>
          <th>Наименование в заказ-наряде</th>
          <th>Кол-во</th>
          <th>Дата и время работы</th>
          <th>Кол-во часов</th>
          <th>Стоимость за час внутренняя, руб.</th>
          <th>Стоимость за час клиентская, руб.</th>
          <th>Скидка, %.</th>
          <th>Общая стоимость внутренняя, руб.</th>
          <th>Общая стоимость клиентская, руб.</th>
          <th className="actions one"></th>
        </tr>
      </thead>
      <tbody>
        {kinds.map(
          (kind) =>
            groupByKind[kind.value] && (
              <GroupByKind
                key={`service-${kind.value}`}
                kind={kind}
                services={groupByKind[kind.value]}
                openServiceFormModal={openServiceFormModal}
                openCloneModal={openCloneModal}
                markServiceForDestroy={markServiceForDestroy}
                onCopyServicesAdded={onCopyServicesAdded}
              />
            )
        )}
      </tbody>
      <tfoot>
        {values.isWithAc && (
          <tr>
            <th colSpan="8" className="text-right">
              Агентская комиссия
            </th>
            <th></th>
            <th>{formatMoney(acCost)}</th>
            <th></th>
          </tr>
        )}
        <tr>
          <th colSpan="8" className="text-right">
            Итого
          </th>
          <th>{formatMoney(innerCostSum)}</th>
          <th>{formatMoney(outerCostSum + acCost)}</th>
          <th></th>
        </tr>
        {values.calculationKind.value === "nds" && (
          <Fragment>
            <tr>
              <th colSpan="8" className="text-right">
                НДС 20%:
              </th>
              <th></th>
              <th>{formatMoney(ndsCost)}</th>
              <th></th>
            </tr>
            <tr>
              <th colSpan="8" className="text-right">
                Итого с учётом НДС:
              </th>
              <th></th>
              <th>{formatMoney(outerCostSum + acCost + ndsCost)}</th>
              <th></th>
            </tr>
          </Fragment>
        )}
        {values.calculationKind.value === "nds_five" && (
          <Fragment>
            <tr>
              <th colSpan="8" className="text-right">
                НДС 5%:
              </th>
              <th></th>
              <th>{formatMoney(ndsCost)}</th>
              <th></th>
            </tr>
            <tr>
              <th colSpan="8" className="text-right">
                Итого с учётом НДС:
              </th>
              <th></th>
              <th>{formatMoney(outerCostSum + acCost + ndsCost)}</th>
              <th></th>
            </tr>
          </Fragment>
        )}
        <tr>
          <th colSpan="8" className="text-right">
            Сметная прибль
          </th>
          <th>{formatMoney(outerCostSum + acCost - innerCostSum)}</th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </table>
  )
}

export default Table
